exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-barcode-label-converter-bmp-to-zpl-tsx": () => import("./../../../src/pages/barcode-label-converter/bmp-to-zpl.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-bmp-to-zpl-tsx" */),
  "component---src-pages-barcode-label-converter-gif-to-zpl-tsx": () => import("./../../../src/pages/barcode-label-converter/gif-to-zpl.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-gif-to-zpl-tsx" */),
  "component---src-pages-barcode-label-converter-index-tsx": () => import("./../../../src/pages/barcode-label-converter/index.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-index-tsx" */),
  "component---src-pages-barcode-label-converter-jpeg-to-zpl-tsx": () => import("./../../../src/pages/barcode-label-converter/jpeg-to-zpl.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-jpeg-to-zpl-tsx" */),
  "component---src-pages-barcode-label-converter-pdf-to-zpl-tsx": () => import("./../../../src/pages/barcode-label-converter/pdf-to-zpl.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-pdf-to-zpl-tsx" */),
  "component---src-pages-barcode-label-converter-png-to-zpl-tsx": () => import("./../../../src/pages/barcode-label-converter/png-to-zpl.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-png-to-zpl-tsx" */),
  "component---src-pages-barcode-label-converter-zpl-to-pdf-tsx": () => import("./../../../src/pages/barcode-label-converter/zpl-to-pdf.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-zpl-to-pdf-tsx" */),
  "component---src-pages-barcode-label-converter-zpl-to-png-tsx": () => import("./../../../src/pages/barcode-label-converter/zpl-to-png.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-zpl-to-png-tsx" */),
  "component---src-pages-barcode-label-converter-zpl-viewer-tsx": () => import("./../../../src/pages/barcode-label-converter/zpl-viewer.tsx" /* webpackChunkName: "component---src-pages-barcode-label-converter-zpl-viewer-tsx" */),
  "component---src-pages-barcode-label-designer-index-tsx": () => import("./../../../src/pages/barcode-label-designer/index.tsx" /* webpackChunkName: "component---src-pages-barcode-label-designer-index-tsx" */),
  "component---src-pages-buy-pricing-tsx": () => import("./../../../src/pages/buy/pricing.tsx" /* webpackChunkName: "component---src-pages-buy-pricing-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

